import React, { Component, Fragment } from 'react';  
import SignaturePad from "react-signature-canvas";
import Popup from "reactjs-popup";
import { SubmitButton } from "../accountBox/common";
import "./sigCanvas.css"
class RequestAccess extends Component {  
    
    constructor(props) {  
        super(props);  
        this.state = {  
            showModal: false  
        };  
    }  
    clear = () => this.props.sigCanvas.current.clear();
    save = () =>
    //console.log(this.props.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
    this.props.save(this.props.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  
    isShowModal = (status) => {  
        this.handleClose();  
        this.setState({ showModal: status });  
    }  
  
    handleClose = () => {  
        this.props.onPopupClose(false);  
    }
    render() {  
        return (  
            <Popup
      modal
      open={this.props.showModalPopup}
      closeOnDocumentClick={false}
    >
      {close => (
        <>
          <SignaturePad
            ref={this.props.sigCanvas}
            canvasProps={{
              className: "signatureCanvas"
            }}
          />
          {/* Button to trigger save canvas image */}
          <div id="outer">
          <div className="inner">
          <SubmitButton onClick={this.save}>Save</SubmitButton>
          </div>
          <div className="inner inner-padding">
          <SubmitButton onClick={this.clear}>Clear</SubmitButton>
          </div>
          <div className="inner">
          <SubmitButton onClick={this.handleClose}>Close</SubmitButton>
          </div>                              
          </div>
          
        </>
      )}
    </Popup>
  
        );  
    }  
}  
  
export default (RequestAccess);  