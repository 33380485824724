import "./pet-list.css"
import { useNavigate } from 'react-router-dom';
import Header from '../privacy/header';
import { SubmitButton } from "../accountBox/common";
import Collapsible from 'react-collapsible';
import React, { useState, useEffect, useRef } from "react";
import PetListPopUp from "../utility/PetListPopUp";
import { fetchGet, fetchPost } from "../../common/api";
import { environment } from "../../Constant/urlConst";

const PetList = () => {
    const nameData = useRef({});
    const breedData = useRef({});
    const noteData = useRef({});
    const [petData, setPetData] = useState([]);
    const [showModalPopup, setShowModalPopup] = useState(false);
    const navigate = useNavigate();
    const element = <div className="collapsable-header">1. Choi (name) Poodle, Photo Count 87.</div>;
    
    const nevigateToPetPhotoPage = (item, index) => {
        //navigate('/petdetails', {petId: 2} );
        navigate(`/petdetails/${JSON.stringify(item)}`)
    };
    const isShowPopup = (status) => {
        setShowModalPopup(status);
    };
    const clear = () => {
        //sigCanvas.current.clear();
        console.log('petData-- ' + JSON.stringify(petData))
    }

    const save = (data) => {
        // var data = {
        //     "name": this.props.nameData.current.value,
        //     "breed": this.props.breedData.current.value,
        //     "note": this.props.noteData.current.value,
        //  }
        setPetData([...petData, data]);
        console.log('petData-- ' + JSON.stringify(petData))
        AddPetData(data);
    }
    const AddPetData = (petData) => {  
        let letUserData = JSON.parse(localStorage.getItem('user'));
        let userId = letUserData.user._id;      
        let req = {
            "name": petData.name,
            "type": petData.note,
            "breed": petData.breed,
            "hasgrowthphoto": 'true',
        }
        fetchPost(`${environment.BASE_URL+environment.PET_LIST+userId}`, req).then(
            (response) => {
              if (response) {
                console.log(`response${response}`)           
                if(response.message=="Success")
                {
                    //localStorage.setItem('user', JSON.stringify(response));
                    //localStorage.removeItem('userinfo');
                    //navigate('/imagesample');
                }
                else{
                    alert('Semothing went wrong!! Please try later.');
                }
              }
              else{
                alert('Semothing went wrong!! Please try later.');
              }
            }
          );
    }

    // function deletePet() {
    //     console.log('delete pet');
    //     console.log('petData-- ' + JSON.stringify(petData))
    // }
    const deletePet = (item, targetIndex) => {
        console.log(JSON.stringify(item)+' -index- '+ targetIndex);
        const newArray = petData.filter((item, index) => index !== targetIndex);
        setPetData(newArray);
      };
    function AddPet() {
        //navigate('/imageupload');
        //navigate('/petlist');
        setShowModalPopup(true);
    }
    return (
        <div className="parent-div">
            <Header headerText={'Pet List'} />
            {/* <div className="div-button-navigate-upload">
                <SubmitButton type="submit" onClick={AddPet}> + Add Pet</SubmitButton>
            </div> */}
            <div className="petlist-anchor-padding"><a className="petlist-anchor" onClick={AddPet}>
                [+] Add Pet
            </a></div>
            {petData?.length > 0 ?
                <div>
                    <ul>
                        {petData.map((item, index) => (
                            <li key={index} className="list-text-left">
                                <Collapsible trigger={item.name}>
                                    <p>
                                        {`(${index+1}) ` +item.name}
                                    </p>
                                    <p>
                                        {item.breed}
                                    </p>
                                    <p>
                                        {item.note}
                                    </p>
                                    <p>
                                        {item.name}
                                    </p>
                                    <div className="petlist-anchor-div"><a className="petlist-anchor"  onClick={() => nevigateToPetPhotoPage(item, index)}>
                                        Go to pet photo page
                                    </a></div>

                                    <div><a className="petlist-anchor" onClick={() => deletePet(item, index)}>
                                        Delete this one
                                    </a></div>
                                </Collapsible>
                            </li>
                        ))}
                    </ul>
                </div> : <div className="parent-no-pet-msg"><div className="child-no-pet-msg"> Pet not available, Add a pet to upload images. </div></div>

            }

            <PetListPopUp
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                nameData={nameData}
                breedData={breedData}
                noteData={noteData}
                clear={clear}
                save={save}
            ></PetListPopUp>
        </div>
    );
};
export default PetList;