import "./pet-details.css"
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../privacy/header';
import { SubmitButton } from "../accountBox/common";
import React, { useState, useEffect, useRef } from "react";
import PetListEditpUp from "../PetListEditPopUp/PetListEditPopUp";
import { fetchGet, fetchPost, fetchDelete, fetchPut } from "../../common/api";
import { environment } from "../../Constant/urlConst";
import { getJwtToken } from "../../common/Utils";
import Collapsible from 'react-collapsible';
import ImagePopUp from "../ImagePopUp/ImagePopUpModel";
import { RiDeleteBin7Fill } from "react-icons/ri";
import { Grid } from "@mui/material";
const PetDetails = () => {
    const nameData = useRef({});
    const breedData = useRef({});
    const noteData = useRef({});

    const [petData, setPetData] = useState('');
    const [petName, setPetName] = useState('');
    const [petBreed, setPetBreed] = useState('');
    const [petNote, setPetNote] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [deleteCount, setDeleteCount] = useState(0);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedImageToPreviewPopUp, setSelectedImageToPreviewPopUp] = useState('');
    const navigate = useNavigate();
    const { petId } = useParams();
    var item = JSON.parse(petId);
    console.log('item-- ' + JSON.stringify(item));

    useEffect(() => {
        console.log('responseJson--name-- ' + item.name);
        console.log('responseJson--breed-- ' + item.breed);
        console.log('responseJson--note-- ' + item.type);
        localStorage.setItem('selectedPetBreed', item.breed);
        localStorage.setItem('selectedPetType', item.type)
        setPetData(item.name);
        setPetName(item.name);
        setPetBreed(item.breed);
        setPetNote(item.type);
        setImages();
    }, []);

    function setImages() {
        fetchGet(`${environment.BASE_URL + environment.PHOTO + item._id}`, getJwtToken()).then(
            (response) => {
                if (response) {
                    console.log(`response${JSON.stringify(response)}`)
                    setImagePreviews(response.photos);
                }
                else {
                    alert('Something went wrong!! Please try later.');
                }
            },
            (err) => {
                console.log(`error ${err}`);
                navigate('/signout');
            }
        );

    }

    const isShowPopup = (status) => {
        setShowModalPopup(status);
    };
    const clear = () => {
        console.log('petData-- ' + JSON.stringify(petData))
    }

    const save = (data) => {
        console.log(JSON.stringify(data));
        updatePetInfo(data);
    }
    const nevigateToAddPhotoPage = (item) => {
        navigate(`/imageupload/${JSON.stringify(item)}`)
    };
    const deletePet = (item) => {
        console.log(JSON.stringify(item));
    };
    const handleCheckboxChange = (e) => {
        console.log('handleCheckboxChange');
        console.log('path--' + JSON.stringify(imagePreviews));


        const { name, checked } = e.target;
        console.log(name + " -- " + checked)
        if (name === "allSelect") {
            let tempUser = imagePreviews.map((user) => {
                return { ...user, isChecked: checked };

            });
            setImagePreviews(tempUser);
        } else {
            let tempUser = imagePreviews.map((user) =>
                user.name === name ? { ...user, isChecked: checked } : user
            );
            setImagePreviews(tempUser);
        }
    };
    const onItemClickHandler = (item, index) => {
        let tempUser = imagePreviews.map((user) =>
            user._id === item._id ? { ...user, isChecked: !item.isChecked } : user
        );
        setImagePreviews(tempUser);
    }
    function checkBoxItemChecked(item) {
        console.log("onItemClickHandler-- " + item);
        let tempUser = imagePreviews.map((user) =>
            user._id === item._id ? { ...user, isChecked: !item.isChecked } : user
        );
        setImagePreviews(tempUser);
      }
    const editPetInfo = (item) => {
        setShowModalPopup(true);
    }
    const onDeleteSelectedPhotos = () => {
        let checkedImages = imagePreviews.filter((item) => item.isChecked == true)
        if (checkedImages.length > 0) {
            checkedImages.forEach(function (item, index) {
                deletePetSelectedImages(item._id, checkedImages.length, index);
            });
        }
        else {
            alert('Please add and select image to delete');
        }


    };
    function deletePetSelectedImages(imageId, listSize, index) {

        fetchDelete(`${environment.BASE_URL + environment.PHOTO + imageId}`, getJwtToken()
        ).then(
            (response) => {

                if (response) {
                    console.log(`response${JSON.stringify(response)}`)
                    if (response.message == "Success") {
                        console.log('listSize-- ' + listSize + 'index-- ' + index)
                        let arrayPosition = parseInt(index);
                        if (listSize == arrayPosition + 1) {
                            setImages();
                        }
                        console.log('imageId-- ' + imageId);
                        //setImagePreviews(imagePreviews.filter((i)=>(i._id !== imageId)))
                    }
                    else {
                        alert('Semothing went wrong!! Please try later.');
                    }
                }
                else {
                    alert('Semothing went wrong!! Please try later.');
                }
            },
            (err) => {
                console.log(`error ${err}`);
                navigate('/signout');
            }
        );
    }
    function updatePetInfo(data) {
        let req = {
            "name": data.name,
            "type": data.type,
            "breed": data.breed,
            "hasgrowth": data.singleid,
            //"_csrf": token
        }

        fetchPut(`${environment.BASE_URL + environment.PET_LIST + item._id}`, req, getJwtToken()
        ).then(
            (response) => {
                if (response) {
                    console.log(`response${JSON.stringify(response)}`)
                    if (response.message == "Success") {
                        setPetData(data.name)
                        setPetName(data.name);
                        setPetBreed(data.breed);
                        setPetNote(data.type);
                        localStorage.setItem('selectedPetBreed', data.breed);
                        localStorage.setItem('selectedPetType', data.type)
                    }
                    else {
                        alert('Semothing went wrong!! Please try later.');
                    }
                }
                else {
                    alert('Semothing went wrong!! Please try later.');
                }
            },
            (err) => {
                console.log(`error ${err}`);
                navigate('/signout');
            }
        );
    }
    const isShowImagePreviewPopup = (status) => {
        setSelectedImageToPreviewPopUp(status);
    };
    function showImage(img) {
        console.log('img-- ' + img);
        setSelectedImage(img);
        setSelectedImageToPreviewPopUp(true);
      }
    const Checkbox = ({ label, value, onChange, name, disabled }) => {
        return (
            <label style={{ fontSize: '17px' }} className="label-style">
                <input type="checkbox" className="check-all" checked={value} onChange={onChange} name={name} disabled={disabled} />
                {label}
            </label>
        );
    };
    return (
        <div>
            <div className="parent-div">
                {/* <Header headerText={item.name} /> */}
                <Header headerText={'Uploaded Photos of ' + item.name} page={-1} showback={true} />
            </div>
            <div className="app-container-padding">
                <div className="pet-details-parent-container-div">
                    <Collapsible trigger='Pet information' open='true'>
                        <div style={{ paddingTop: '0' }} className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-uja33m">
                            <div className="pet-details-container-div">
                                <div className="MuiBox-root css-1jk11b0">
                                    {/* <h6 className="MuiTypography-root MuiTypography-h6 css-92y519">pet information</h6> */}
                                    <div className="MuiBox-root css-a9sjrj" style={{ paddingBottom: '0' }}>
                                        <span className="MuiTypography-root MuiTypography-button css-1i8v7ix">pet name: &nbsp;</span>
                                        <span className="MuiTypography-root MuiTypography-button css-bdg3h7">&nbsp;{petName}</span>
                                    </div>
                                    <div className="edit-pencil" onClick={() => editPetInfo(item)}></div>
                                </div>
                                <div className="pet-details-child-container-div">
                                    {/* <div className="MuiBox-root css-a9sjrj">
                                        <span className="MuiTypography-root MuiTypography-button css-1i8v7ix">pet name: &nbsp;</span>
                                        <span className="MuiTypography-root MuiTypography-button css-bdg3h7">&nbsp;{petName}</span>
                                    </div> */}
                                    <div className="MuiBox-root css-a9sjrj" >
                                        <span className="MuiTypography-root MuiTypography-button css-1i8v7ix">Breed: &nbsp;</span>
                                        <span className="MuiTypography-root MuiTypography-button css-bdg3h7">&nbsp;{petBreed}</span>
                                    </div>
                                    <div className="MuiBox-root css-a9sjrj">
                                        <span className="MuiTypography-root MuiTypography-button css-1i8v7ix">Pet Type: &nbsp;</span>
                                        <span className="MuiTypography-root MuiTypography-button css-bdg3h7">&nbsp;{petNote}</span>
                                    </div>

                                    {/* <div className="petlist-anchor-div pet-details-bottom-div"><a className="pet-details-anchor" onClick={() => nevigateToAddPhotoPage(item)}>
                                    Add photos
                                </a></div> */}

                                    {/* <div><a className="pet-details-anchor" onClick={() => deletePet(item)}>
                            Delete this pet
                        </a></div> */}
                                </div>
                            </div>
                        </div>
                    </Collapsible>

                    <div className="control-box">
                        <div className="add-button-div-style">
                            <button
                                variant="outline-primary" size="lg"
                                onClick={() => nevigateToAddPhotoPage(item)}
                                className="btn btn-success btn-sm add-button-style-pet-details">
                                Upload photos
                            </button>
                        </div>
                        <div className="row row_left-pet-details">
                            <div style={{ width: '50%', float: 'left' }}>
                                <Checkbox
                                    label={(imagePreviews?.length == 0 || imagePreviews.some((image) => image?.isChecked !== true)) ? 'Check All' : 'Uncheck All'}
                                    value={(imagePreviews.length > 0 && !imagePreviews.some((image) => image?.isChecked !== true)) ? true : false}
                                    onChange={handleCheckboxChange}
                                    name="allSelect"
                                    disabled={(imagePreviews === 'undefined' || imagePreviews?.length <= 0) ? true : false}
                                />
                            </div>

                            <div style={{ width: '50%', float: 'right', textAlign: 'right', paddingRight: '20px', paddingTop: '5px' }}>
                            <RiDeleteBin7Fill onClick={() => onDeleteSelectedPhotos()} color={ (imagePreviews?.length > 0 && imagePreviews.some((image) => image?.isChecked === true)) ? "#0096FF" : "lightgray"} size={"2em"}/>
                                {/* <button onClick={() => onDeleteSelectedPhotos()} className="css-red-button" tabIndex="0" type="button"><i className="fa fa-trash" style={{ fontSize: '26px', color: '#2196F3' }}></i></button> */}
                            </div>

                        </div>
                    </div>
                    <div>
                        {imagePreviews && (
                            <div className="showImageContainer">
                                <Grid container spacing={2}>
                                    {[...imagePreviews].map((data, index) => {
                                        console.log('data.isChecked-- ' + data.isChecked)
                                        return (                                            
                                            <Grid item xs={6} key={index}>
                                                <div className="cont-main img-con-div">
                                                    <div className="cont-checkbox">
                                                        <input className="display_none" type="checkbox" name={data.filename}
                                                            checked={data?.isChecked || false}
                                                            onChange={handleCheckboxChange}
                                                        />

                                                        <label key={index}>
                                                            <img className="preview" onClick={() =>showImage(`${environment.BASE_URL}` + 'uploadFiles/' + data.filename)} style={{ border: "none", width: "40vw", height: "40vw", marginBottom: '-7px' }} src={`${environment.BASE_URL}` + 'uploadFiles/' + data.filename} alt={data.filename} key={index} />
                                                            <div className="div-checkbox" onClick={() => checkBoxItemChecked(data)}>
                                                            <span className="cover-checkbox">
                                                                <svg viewBox="0 0 12 10">
                                                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                                </svg>
                                                            </span>
                                                            </div>
                                                            
                                                        </label>
                                                    </div>
                                                </div >
                                            </Grid>
                                        );
                                    })}
                                </Grid>

                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ImagePopUp
                showModalPopup={selectedImageToPreviewPopUp}
                imgSrc={selectedImage}
                onPopupClose={isShowImagePreviewPopup}                
            ></ImagePopUp>
            <PetListEditpUp
                showModalPopup={showModalPopup}
                petData={petData}
                petName={petName}
                petBreed={petBreed}
                petNote={petNote}
                onPopupClose={isShowPopup}
                nameData={nameData}
                breedData={breedData}
                noteData={noteData}
                clear={clear}
                save={save}
            ></PetListEditpUp>
        </div>
    );
};
export default PetDetails;