import logo from './logo.svg';
import './App.css';
import "./style.css";
import styled from "styled-components";
import AccountBox from "./components/accountBox/index"
import { Routes, Route } from 'react-router-dom';
import HomePage from './components/home/HomePage';
import ImagesUpload from "./components/ImagesUpload/ImagesUpload";
import Chatbot from './components/chatbot/chatbot';
import PrivacyPolicy from './components/privacy/privacyPolicy';
import AggrementScreen from './components/agreement/Agreement';
import ImageSample from './components/imageSample/imageSample';
import UserInfoScreen from './components/userInfo/UserInfo';
import PrivacyPolicyAgreement from './components/PrivacyPolicyAgreement/privacyPolicyAgreement';
import PetList from './components/petList/petList';
import PetDetails from './components/petDetails/petDetails';
import PetListAll from './components/PetListAll/petListAll';
import LoadingScreen from './components/Loader/LoadingScreen';
import SignOut from './components/SignOut/signout';
import PdfViewer from './components/PdfViewer/pdfViewer';

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(240, 242, 245);
`;
function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <Routes>
      <Route path="/:singleid?" element={
        /*<AppContainer>
          <AccountBox />
      </AppContainer>*/
        // <Chatbot />
        <LoadingScreen />
        // <SignOut/>
      }
      />      
      <Route path="loader/:singleid?" element={<LoadingScreen />} />
      <Route path="signout" element={<SignOut />} />
      <Route path="home" element={<HomePage />} />
      <Route path="imageupload/:petId" element={<ImagesUpload />} />
      {/* <Route path="chatbot" element={<Chatbot />} /> */}
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="privacy-policy-agreement" element={<PrivacyPolicyAgreement />} />
      <Route path="agreement" element={<AggrementScreen />} />
      <Route path="imagesample" element={<ImageSample />} />
      <Route path="userinfo" element={<UserInfoScreen />} />
      <Route path="petlist" element={<PetList />} />
      <Route path="petdetails/:petId" element={<PetDetails />} />
      <Route path="petlistall" element={<PetListAll />} />
      <Route path="pdfviewer" element={<PdfViewer />} />
    </Routes>

  );
}

export default App;
