import React, { Component, Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import SignaturePad from "react-signature-canvas";
import Popup from "reactjs-popup";
import { SubmitButton } from "../accountBox/common";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import "./image-popup.css"

class ImagePopUp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  clear = () => {
    this.props.nameData.current.value = '';
  }
  close = () => {
    this.handleClose();
  }


  isShowModal = (status) => {
    this.handleClose();
    this.setState({ showModal: status });
  }

  handleClose = () => {
    this.props.onPopupClose(false);
  }
  handleIncludeHumanFaceChkBox = (e) => {
    this.setState({ includeHuman: e.target.checked });
  }
  handleIncludeMultiplePetChkBox = (e) => {
    this.setState({ multiplePet: e.target.checked });
  }
  render() {
    return (
      <Popup
        modal
        open={this.props.showModalPopup}
        closeOnDocumentClick={false}
      >
        {close => (
          <>
            <div className="container">
              <Zoom>
                <img
                  alt="Snow"
                  src={this.props.imgSrc}
                  width="100%"
                />
              </Zoom>
              <button className="btn" onClick={this.close}>X</button>
            </div>

          </>
        )}
      </Popup>

    );
  }
}

export default (ImagePopUp);  