import { InfinitySpin } from 'react-loader-spinner'
import "./loader.css"


const Loader = () => {
    return (
        <div className="loader-div">
            <div>
            <div>
                <InfinitySpin
                    visible={true}
                    width="200"
                    color="#2196F3"
                    ariaLabel="infinity-spin-loading"
                />
            </div>
            <div className='text-div'>
                <span className='text-span'>Please wait..</span>
            </div>
            </div>
        </div>
    );
};

export default Loader;