function updateJwtToken(jwt) {
  localStorage.setItem('jwt', jwt);
};
function getJwtToken() {
  var jwtToken = localStorage?.getItem('jwt');
  let jwt = '';
  if (jwtToken)
    jwt = jwtToken
  return jwt;
};

function updateUserSignOutStatus() {
  localStorage.removeItem("privacy");
  localStorage.removeItem("privacyagreement");
  localStorage.setItem('signinstatus', '0');
  localStorage.removeItem('userinfo');
  localStorage.removeItem('user');

}
function updateUserSignInStatus() {
  localStorage.setItem('signinstatus', '1');
}

export { updateJwtToken, getJwtToken, updateUserSignInStatus, updateUserSignOutStatus };
