import axios from 'axios';
function SetToken(csrf, token) {
    axios.defaults.withCredentials = true
    axios.interceptors.request.use(
        config => {
            //let token = localStorage.getItem('token');
            //let empID = localStorage.getItem('empID');
            
            if (token) {  
                
                config.headers.Authorization = `${token}`;
                config.headers["Content-Type"] = 'application/json';
                //config.headers.Cookie = "_csrf="+csrf+"; access_key="+token+"";
                //config.headers.UserID = `${empID}`;
                
                //config.headers.Cookie = `${token}`;
                //config.withCredentials = true;
            }
            return config;
        },
        err => {
            return Promise.reject(err);
        });
}


function fetchPost(url, params,config = {}) {    
    return new Promise((resolve, reject) => {        
        //axios.post(url, params,config)
        axios.post(url, params,{ headers: {"Authorization" : `${config}`} })
            .then(response => {                
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
               reject(error)
            })
    })

}
function fetchGet(url, config = {}) {    
    return new Promise((resolve, reject) => {
         //let param={params:paramObj}
           axios.get(url,{ headers: {"Authorization" : `${config}`}} )
            .then(response => {                
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}
function fetchDelete(url, config = {}) {
    return new Promise((resolve, reject) => {
         //let param={params:paramObj}
           axios.delete(url,{ headers: {"Authorization" : `${config}`}})
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}
function fetchPut(url, params,config = {}) {

    return new Promise((resolve, reject) => {
        axios.put(url, params, { headers: {"Authorization" : `${config}`}})
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
               reject(error)
            })
    })

}

function downloadPdf(){
    axios.get("https://ncu.rcnpv.com.tw/Uploads/20131231103232738561744.pdf",
  {
    headers: {
      "Content-Type": "application/pdf",
    },
    
    responseType: 'blob'
  },
)
  .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Test'+'.pdf');
      document.body.appendChild(link);
      link.click();
  })
}

export { fetchGet, fetchPost, downloadPdf, fetchDelete, SetToken, fetchPut };
