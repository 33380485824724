import React from "react";
import "./header.css"
import 'font-awesome/css/font-awesome.min.css';
import { useNavigate } from 'react-router-dom';
import '../../../node_modules/font-awesome/css/font-awesome.min.css'; 
const HeaderWithoutback = ({headerText}) => {
    const navigate = useNavigate();
    function headerGoBack(){
        console.log('headerGoBack');
        navigate(-1);
    }
    return (
        <div>
            <div className="row-header">                
                <div className="column-header middle-header-col-center">
                    <h2 className="header-title">{headerText}</h2>
                </div>                
            </div>
        </div>
    );
}

export default HeaderWithoutback;