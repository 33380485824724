import { InfinitySpin } from 'react-loader-spinner'
import "./loader.css"
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from "react";
import { fetchGet } from "../../common/api";
import { environment } from "../../Constant/urlConst";
import { updateJwtToken, getJwtToken, updateUserSignOutStatus } from "../../common/Utils";
import { msg } from '../../Constant/commonMsg';

const LoadingScreen = () => {
    const navigate = useNavigate();
    const isOnline = navigator.onLine;
    const { singleid = environment.DEFAULT_SINGLE_ID } = useParams();
    console.log(':singleid - ' + singleid)
    
    useEffect(() => {
        if (isOnline) {
            getUser();
          } else {
            alert(msg.NO_INTERNET);
          }        
    }, []);

    function getUser() {
        localStorage.removeItem('userinfo');
        localStorage.removeItem('user');
        localStorage.removeItem("privacy");
        localStorage.removeItem("privacyagreement");
        updateUserSignOutStatus();
        fetchGet(`${environment.BASE_URL + environment.USER + '?singleid=' + singleid }`).then(
            (response) => {
                if (response.userType == 'new user') {
                    if(response?.jwt)                
                    updateJwtToken(response?.jwt);
                    localStorage.setItem('usertype', '0');
                    localStorage.setItem('user', JSON.stringify(response));
                    console.log(`new user - ${JSON.stringify(response)}`)
                    navigate('/agreement');
                }
                else if (response.userType == 'existing user') {
                    if(response?.jwt)
                    updateJwtToken(response?.jwt);
                    localStorage.setItem('usertype', '1');
                    localStorage.setItem('user', JSON.stringify(response.user));
                    console.log(`existing user - ${JSON.stringify(response)}`)
                    //navigate('/imagesample');
                    navigate('/agreement');   
                }
                else {
                    alert('Something went wrong!! Please try later.');
                }
            }
        );

    }

    return (
        <div className="loading-screen">
            <div>
            <div>
                <InfinitySpin
                    visible={true}
                    width="200"
                    color="#2196F3"
                    ariaLabel="infinity-spin-loading"
                />
            </div>
            <div className='text-div'>
                <span className='text-span'>Please wait..</span>
            </div>
            </div>
        </div>
    );
};

export default LoadingScreen;