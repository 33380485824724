import "./userinfo.css"
import { useNavigate } from 'react-router-dom';
import { SubmitButton } from "../accountBox/common";
import React, { useState } from "react";
import Header from "../privacy/header";

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

const UserInfoScreen = () => {
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        // more inputs...
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
        console.log(formValues.email);
    };
    const getIsFormValid = () => {
        return (
            formValues.firstName &&
            formValues.lastName &&
            validateEmail(formValues.email)
        );
    };
    const handleSubmit = (e) => { 
        e.preventDefault(); 
        //alert(formValues.firstName + '-  -'+ formValues.lastName+ '-  -'+ formValues.email);         
        clearForm(); 
        navigate('/agreement');
      };
      const clearForm = () => { 
        //setFormValues({ ...formValues, ['firstName']: '' });
        //setFormValues({ ...formValues, ['lastName']: '' });
        //setFormValues({ ...formValues, ['email']: '' });
        // setFormValues({ firstName: "" });
        // setFormValues({ lastName: "" });
        // setFormValues({ email: "" });
        setFormValues({
            firstName: "",
            lastName: "",
            email: ""
          });
      }; 

    return (
        <div className="parent-div-chatbot">
            <Header headerText={'Your Info'} />
            <div className="App">
                <form onSubmit={handleSubmit}>
                    <fieldset>
                        <div className="Field">
                            <label>
                                First name <sup>*</sup>
                            </label>
                            <input
                                className="samsung-font"
                                name="firstName"
                                value={formValues.firstName}
                                onChange={handleChange}
                                placeholder="First name"
                            />
                        </div>
                        <div className="Field">
                            <label>
                                Last name <sup>*</sup>
                            </label>
                            <input
                                className="samsung-font"
                                name="lastName"
                                value={formValues.lastName}
                                onChange={handleChange}
                                placeholder="Last name"
                            />
                        </div>
                        <div className="Field">
                            <label>
                                Email address <sup>*</sup>
                            </label>
                            <input
                                className="samsung-font"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                                placeholder="Email address"
                            />
                        </div>
                        {/* <button type="submit" disabled={!getIsFormValid()}>
                            Submit
                        </button> */}
                        <div>
                        <SubmitButton type="submit" className="button-submit-userinfo" disabled={!getIsFormValid()}>Submit</SubmitButton>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );

};
export default UserInfoScreen;