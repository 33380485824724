import React from "react";
import "./header.css"
import 'font-awesome/css/font-awesome.min.css';
import { useNavigate } from 'react-router-dom';
import '../../../node_modules/font-awesome/css/font-awesome.min.css'; 
import backIcon from './arrow-left.png'; //

const Header = ({headerText, page=-1, showback=false}) => {
    const navigate = useNavigate();    
    function headerGoBack(){
        console.log('headerGoBack');        
        navigate(page);
    }
    return (
        <div>
            <div className="row-header">
                
                <div className="column-header middle-header-col">
                { showback ? <div className="column-header1 left-header-col" onClick={headerGoBack}>                    
                    <img src={backIcon} className="back-icon-header" style={{ height: '25px', color: '#FFFFFF' }}/>
                </div> : ''}
                    <h2 className="header-title headet-text-h2">{headerText}</h2>
                </div>                
                
            </div>
        </div>
    );
}

export default Header;