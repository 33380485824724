export const stringTexts = {
    IMAGE_EXAMPLE_TEXT1: 'Below is an example of collected photoes, OOO. Please refer it and participate with various photoes that match the contents of the collection.',
    IMAGE_EXAMPLE_TEXT2: 'Please refrain from uploading photos that may invade the privacy of yourself or others.',
    IMAGE_EXAMPLE_TEXT3: 'Please be careful not to upload sensative personal information or inappropriate photos.',
    AGREEMENT_TEXT1: 'Thank you for participating in AI Data Collection, OOO',
    AGREEMENT_TEXT2: 'The data we are collecting this time is pet photo.',
    AGREEMENT_TEXT3: 'You must agree to the following agreement for the collection and use of personal information before uploading your photos.',
    AGREEMENT_TEXT4: 'Personal information is also used to provide compensation for those who participate.',
    AGREEMENT_TEXT5: 'For the classification and curation of collected data we ask for your personal information such as gender and age group.',
    PET_BREEDS: [{
        "id": 1,
        "value": "Pet Breed",
        "name": "Pet Breed"        
    },
    {
        "id": 2,
        "value": "Pet Breed 1",
        "name": "Pet Breed 1"
    },
    {
        "id": 3,
        "value": "Pet Breed 2",
        "name": "Pet Breed 2"
    }],
    PET_TYPE: [{
        "id": 1,
        "value": "Pet Type",
        "name": "Pet Type"        
    },
    {
        "id": 2,
        "value": "Dog",
        "name": "Dog"
    },
    {
        "id": 3,
        "value": "Cat",
        "name": "Cat"
    }]
};