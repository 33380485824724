import React, { Component, Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import SignaturePad from "react-signature-canvas";
import Popup from "reactjs-popup";
import { SubmitButton } from "../accountBox/common";
import { msg } from "../../Constant/commonMsg";
import "./pet-list-edit-popup.css"
import { stringTexts } from '../../Constant/texts';


class PetListEditpUp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      includeHuman: false,
      multiplePet: false,
      petType: 'Pet Type',
      petBreed: 'Pet Breed',
      selectedOption: null,
      breedAddStatus: false,
      petBreedData:[]
    };
  }
  // const { register, handleSubmit } = useForm();
  //   const [formData, setFormData] = useState({
  //     name: '',
  //     email: '',
  //     password: '',
  //   });
  componentWillMount() {                      
    this.setState({ petBreed: localStorage.getItem('selectedPetBreed') });
    this.setState({ petType: localStorage.getItem('selectedPetType') });
  }
  clear = () => {
    //this.props.sigCanvas.current.clear();
    this.props.nameData.current.value = '';
    // this.props.breedData.current.value = '';
    // this.props.noteData.current.value = '';
    this.setState({ breedAddStatus: false });
    this.setState({ petBreed: localStorage.getItem('selectedPetBreed') });
    this.setState({ petType: localStorage.getItem('selectedPetType') });
    //this.setState({ petType: 'Pet Type' });
  }
  save = () => {
    // console.log(this.props.nameData.current.value);
    // console.log(this.props.breedData.current.value);
    // console.log(this.props.noteData.current.value);
    // console.log(this.state.includeHuman);
    // console.log(this.state.multiplePet);
    let breedSelectedText = this.state.breedAddStatus ?  this.props.breedData.current.value : this.state.petBreed;
    var data = {
      "name": this.props.nameData.current.value,
      "breed": breedSelectedText,
      "type": this.state.petType,
      "includehuman": this.state.includeHuman,
      "multiplepet": this.state.multiplePet,
    }
    if (this.props.nameData.current.value.trim() == '' || breedSelectedText.trim() == '' || breedSelectedText == 'Pet Breed' || this.state.petType == 'Pet Type')
      alert(msg.ALL_FIELDS_MANDATORY);
    else {      
      this.props.save(data);
      this.handleClose();
    }
  }


  isShowModal = (status) => {
    this.handleClose();
    this.setState({ showModal: status });
  }

  handleClose = () => {
    //this.setState({ petBreed: localStorage.getItem('selectedPetBreed') });
    //this.setState({ petType: localStorage.getItem('selectedPetType') });
    this.props.onPopupClose(false);
  }
  handleIncludeHumanFaceChkBox = (e) => {
    this.setState({ includeHuman: e.target.checked });
    //this.props.includeHuman.value = e.target.checked;    
    //setChecked(e.target.checked);
    //console.log('handleIncludeHumanFaceChkBox-- '+this.props.includeHuman.checked);
  }
  handleIncludeMultiplePetChkBox = (e) => {
    this.setState({ multiplePet: e.target.checked });
    //this.props.multiplePet.current.checked = e.target.checked;
    //console.log('handleIncludeMultiplePetChkBox-- '+this.props.multiplePet.checked);
  }
  handleOnChangePetType = (event) => {
    console.log(event.target.value)
    this.setState({ petType: event.target.value });
  };
  handleOnChangeBreedType = (event) => {
    console.log(event.target.value)
    this.setState({ petBreed: event.target.value });
    //this.props.petBreed.current.value = event.target.value;
  };
  addBreedButton = () => {
    this.setState({ breedAddStatus: true });
  }
  render() {
    return (
      <Popup
        modal
        open={this.props.showModalPopup}
        closeOnDocumentClick={false}
      >
        {close => (
          <>
            {/* <SignaturePad
            ref={this.props.sigCanvas}
            canvasProps={{
              className: "signatureCanvas"
            }}
          /> */}
            {/* Button to trigger save canvas image */}
            <div className='pet-list-popup-main-div'>
              <div className='parent-div-pet-list-popup'>
                <input className='input-pet-list-popup' ref={this.props.nameData} defaultValue={this.props.petName} type="text" name="petname" placeholder="Name" />
                {/* <input className='input-pet-list-popup' ref={this.props.breedData} defaultValue={this.props.petBreed} type="text" name="breed" placeholder="Breed" /> */}
                {/* <input className='input-pet-list-popup' ref={this.props.noteData} defaultValue={this.props.petNote} type="text" name="note" placeholder="Type" /> */}
                {this.state.breedAddStatus ? <input className='input-pet-list-popup' ref={this.props.breedData} type="text" name="breed" placeholder="Pet Breed" />
                  : <div>
                    <select name="pet_type" className="input-petlist-popup" style={{ color: this.state.petBreed == 'Pet Breed' ? 'lightgray' : 'gray' }} value={this.state.petBreed} onChange={this.handleOnChangeBreedType}>
                      {/* <option className='input-text-font' value="Pet Breed">Pet Breed</option>
                      <option className='input-text-font' value="Pet Breed 1">Pet Breed 1</option>
                      <option className='input-text-font' value="Pet Breed 2">Pet Breed 2</option> */}
                      {stringTexts.PET_BREEDS.map((option, i) => (
                                <option style={{color: 'gray'}} key={i} value={option.value}>{option.name}</option>
                            ))}
                    </select>
                    <button className='add-breed-button' onClick={this.addBreedButton}>+</button>
                  </div>
                }
                <div>
                  <select name="pet_type" className="input-petlist-popup" style={{ width: '100%', color: this.state.petType == 'Pet Type' ? 'lightgray' : 'gray' }} value={this.state.petType} onChange={this.handleOnChangePetType}>
                    {/* <option className='input-text-font' value="Pet Type">Pet Type</option>
                    <option className='input-text-font' value="dog">Dog</option>
                    <option className='input-text-font' value="cat">Cat</option> */}
                    {stringTexts.PET_TYPE.map((option, i) => (
                                <option style={{color: 'gray'}} key={i} value={option.value}>{option.name}</option>
                            ))}                    
                  </select>
                  {/* <button className='add-breed-button'>+</button>                   */}
                </div>
              </div>
              {/* <div className='pet-list-checkbox-div'>
                <input className='pet-list-checkbox' ref={this.props.includeHuman} value="true" type="checkbox" onChange={this.handleIncludeHumanFaceChkBox} />
                <span className='pet-list-checkbox-span'>Include human face</span>
              </div>
              <div className='pet-list-checkbox-div'>
                <input className='pet-list-checkbox' ref={this.props.multiplePet} value="true" type="checkbox" onChange={this.handleIncludeMultiplePetChkBox} />
                <span className='pet-list-checkbox-span'>Include multiple pet</span>
              </div> */}
              <div id="outer" className='outer-div-pet-list-popup'>
                <div className="inner">
                  <SubmitButton style={{width: '76px'}} onClick={this.save}>Update</SubmitButton>
                </div>
                <div className="inner inner-padding">
                  <SubmitButton style={{width: '76px'}} onClick={this.clear}>Clear</SubmitButton>
                </div>
                <div className="inner">
                  <SubmitButton style={{width: '76px'}} onClick={this.handleClose}>Close</SubmitButton>
                </div>
              </div>              
            </div>


          </>
        )}
      </Popup>

    );
  }
}

export default (PetListEditpUp);  