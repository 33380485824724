import React, { useState, useEffect, useRef } from "react";
import UploadService from "../../services/FileUploadService";
import SignaturePad from "react-signature-canvas";
import Popup from "reactjs-popup";
import ReactDOM from 'react-dom/client';
import RequestAccess from "../utility/RequestAccess";
import { SubmitButton, Input } from "../accountBox/common";
import "./imageuploader.css"
import "./list.css"
import uploadImg from "./upload.png";
import Header from "../privacy/header";
import { fetchGet, fetchPost, fetchDelete } from "../../common/api";
import { environment } from "../../Constant/urlConst";
import { useNavigate, useParams } from 'react-router-dom';
import { getJwtToken } from "../../common/Utils";
import { msg } from "../../Constant/commonMsg";
import Loader from "../Loader/Loader";
import ImagePopUp from "../ImagePopUp/ImagePopUpModel";
import { Grid, Item } from "@mui/material";
//import { ProgressBar } from "react-bootstrap"; 
//import "bootstrap/dist/css/bootstrap.min.css";
const ImagesUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [message, setMessage] = useState([]);
  //const [checked, setChecked] = React.useState(false);
  const [checked, setChecked] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);
  const progressInfosRef = useRef(null);
  const [imageURL, setImageURL] = useState(null);
  const sigCanvas = useRef({});
  const hiddenFileInput = useRef(null);
  const [checkedAll, setCheckedAll] = useState(false);
  const [imageUploaded, setImageUploadedIndex] = useState(0);
  const [imageUploadedPercentage, setImageUploadedPercentage] = useState(0);
  const [checkedFiles, setCheckedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { petId } = useParams();
  const navigate = useNavigate();
  const isOnline = navigator.onLine;
  var petDetails = JSON.parse(petId);
  console.log('paramsItem-- ' + petDetails._id)
  const clear = () => {
    sigCanvas.current.clear();
  }

  const save = (url) =>
    setImageURL(url);
  let popUpSatate = false;

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const Checkbox = ({ label, value, onChange, name, disabled }) => {
    return (
      <label style={{ fontSize: '18px' }}>
        <input type="checkbox" className="check-all" checked={value} onChange={onChange} name={name} disabled={disabled} style={{ fontSize: '19px' }} />
        {label}
      </label>
    );
  };
  const handleChange = () => {
    setChecked(!checked);
  };


  const selectFiles = (event) => {
    setImageUploadedIndex(0);
    setImageUploadedPercentage(0);
    let images = [];
    if (imagePreviews != undefined) {
      images = imagePreviews;
    }
    let imgName = "";
    for (let i = 0; i < event.target.files.length; i++) {
      console.log(JSON.stringify(URL.createObjectURL(event.target.files[i])))
      const hasImageName = images.some(img => img.name === event.target.files[i].name);
      console.log('hasImageName-- ' + hasImageName)
      if (!hasImageName) {
        let dataObj = { name: event.target.files[i].name, url: URL.createObjectURL(event.target.files[i]), isChecked: true, uploadFile: event.target.files[i] };
        images.push(dataObj);
        console.log('file name-- ' + JSON.stringify(dataObj))
      }
    }
    console.log('imagePreviews.length ' + imagePreviews.length);
    setSelectedFiles(event.target.files);
    setImagePreviews(images);
    setProgressInfos({ val: [] });
    setMessage([]);
  };

  const handleCheckboxChange = (e) => {
    console.log('handleCheckboxChange');
    console.log('path--' + JSON.stringify(imagePreviews));
    console.log('selectedFiles--' + JSON.stringify(Array.from(selectedFiles)));

    const { name, checked } = e.target;
    console.log(name + " -- " + checked)
    if (name === "allSelect") {
      let tempUser = imagePreviews.map((user) => {
        return { ...user, isChecked: checked };

      });
      setImagePreviews(tempUser);
    } else {
      let tempUser = imagePreviews.map((user) =>
        user.name === name ? { ...user, isChecked: checked } : user
      );
      setImagePreviews(tempUser);
    }
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const uploadImages = () => {
    if (isOnline) {
      const date = new Date();
      const timeStamp = date.getFullYear() + "_" + ("" + (date.getMonth() + 1)).slice(-2) + "_" + ("" + date.getDate()).slice(-2) + "_" + ("" + date.getHours()).slice(-2) + "_" + ("" + date.getMinutes()).slice(-2) + "_" + ("" + date.getSeconds()).slice(-2);
      if (imagePreviews.length > 0 && imagePreviews.filter(data => data.isChecked).length > 0) {
        let _progressInfos = imagePreviews.filter(data => data.isChecked).map((file) => ({
          percentage: 0,
          fileName: file.name,
        }));

        progressInfosRef.current = {
          val: _progressInfos,
        };

        const formData = new FormData();
        const uploadPromises = imagePreviews.filter(data => data.isChecked).map((file, i) => {
          formData.append("myFiles", file.uploadFile);
        }
        );
        if (formData)
          uploadPhoto(formData)
        setMessage([]);
      }
      else {
        alert(msg.SELECT_IMAGE);
      }
    } else {
      alert(msg.NO_INTERNET)
    }

  };
  const uploadPhoto = (formData) => {
    setIsLoading(true);
    fetchPost(`${environment.BASE_URL + environment.PHOTO + petDetails._id}`, formData, getJwtToken()).then(
      (response) => {
        setIsLoading(false);
        if (response) {
          console.log(`response${response}`)
          if (response.message == "success") {
            handleDelete();
            alert('Photo uploaded successfully.');
          }
          else {
            alert('Semothing went wrong!! Please try later.');
          }
        }
        else {
          alert('Semothing went wrong!! Please try later.');
        }
      },
      (err) => {
        console.log(`error ${err}`);
        setIsLoading(false);
        navigate('/signout');
      }
    );
  }

  const handleDelete = () => {
    const newArray = imagePreviews.filter(data => !data.isChecked)
    setImagePreviews(newArray);
  };

  const onItemClickHandler = (event) => {
    const { alt } = event.target;
    console.log("onItemClickHandler-- " + alt);
    let data = imagePreviews.filter((item) => item.name == alt)
    let tempUser = imagePreviews.map((user) =>
      user.name === alt ? { ...user, isChecked: !data[0].isChecked } : user
    );
    setImagePreviews(tempUser);
  }

  function checkBoxItemChecked(name) {
    console.log("onItemClickHandler-- " + name);
    let data = imagePreviews.filter((item) => item.name == name)
    let tempUser = imagePreviews.map((user) =>
      user.name === name ? { ...user, isChecked: !data[0].isChecked } : user
    );
    setImagePreviews(tempUser);
  }

  function showImage(img) {
    console.log('img-- ' + img);
    setSelectedImage(img);
    setShowModalPopup(true);
  }
  function close() {
    console.log('image popup close');
  }

  return (
    <div className="image-upload-parent-div">
      {isLoading ? <Loader /> : <div>
        <div className="row">
          <div className="col-8">
            <Header headerText={'Data Upload'} page={-1} showback={true} />
            <div className="app">
              <div className="parent">
                <div>
                  <p className="text-center padding-top">Select and upload data to share.</p>
                  <div className="row">
                    <div className="image-upload-column text-right" style={{paddingRight: '15px'}}>
                      <SubmitButton
                      style={{width: '75%'}}
                        variant="outline-primary" size="lg"
                        onClick={handleClick}
                        className="btn btn-success btn-sm"
                      >
                        Select
                      </SubmitButton>
                      <input
                        type="file"
                        onChange={selectFiles}
                        ref={hiddenFileInput}
                        style={{ display: 'none' }} // Make the file input element invisible
                        accept="image/*"
                        multiple
                      />
                    </div>
                    <div className="image-upload-column text-left" style={{paddingLeft: '15px'}}>
                      <SubmitButton
                      style={{width: '75%'}}
                        variant="outline-primary" size="lg"
                        className="btn btn-success btn-sm"
                        onClick={uploadImages}
                      >
                        Upload
                      </SubmitButton>
                    </div>
                  </div>
                  <div className="row row_left-image-uplaod">
                    <Checkbox
                      label={(imagePreviews?.length == 0 || imagePreviews.some((image) => image?.isChecked !== true)) ? 'Check All' : 'Uncheck All'}
                      value={(imagePreviews.length > 0 && !imagePreviews.some((image) => image?.isChecked !== true)) ? true : false}
                      onChange={handleCheckboxChange}
                      name="allSelect"
                      disabled={(imagePreviews === 'undefined' || imagePreviews?.length <= 0) ? true : false}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div>
          </div>
          {/* <RequestAccess
          showModalPopup={showModalPopup}
          onPopupClose={isShowPopup}
          sigCanvas={sigCanvas}
          clear={clear}
          save={save}
        ></RequestAccess> */}

        </div>
        {/* <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div>xs=8</div>
            </Grid>
            <Grid item xs={6}>
              <div>xs=4</div>
            </Grid>
            <Grid item xs={6}>
              <div>xs=4</div>
            </Grid>
            <Grid item xs={6}>
              <div>xs=8</div>
            </Grid>
          </Grid>
        </div> */}
        {message.length > 0 ? <div className="mb2-image-upload">
          <span className="samsung-font">Picture uploaded</span>
          <div className="samsung-font">
            <progress className="progress-image-upload" value={imageUploadedPercentage} max={100} />
            {imageUploadedPercentage}%
          </div>
        </div> : ''}
        {imagePreviews && (
          <div className="showImageContainer">
            <Grid container spacing={2}>
            
              {[...imagePreviews].map((data, index) => {
                console.log('data.isChecked-- ' + data.name)
                return (

                  <Grid item xs={6} key={index}>
                    <div className="cont-main img-con-div">
                      <div className="cont-checkbox">
                        <input className="display_none" type="checkbox" name={data.name}
                          checked={data?.isChecked || false}
                          onChange={handleCheckboxChange}
                        />

                        <label key={index} style={{ marginBottom: '0px' }}>
                          <img onClick={() => showImage(data?.url)} className="preview" style={{ border: "none", width: "40vw", height: "40vw", marginBottom: '-7px' }} src={data.url} alt={data.name} key={index} />
                          <div className="div-checkbox-image-upload" onClick={() => checkBoxItemChecked(data.name)}>
                          <span className="cover-checkbox">
                            <svg viewBox="0 0 12 10">
                              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg>
                          </span>
                          <span></span>
                          </div>                          
                        </label>
                      </div>
                    </div >
                  </Grid>


                  // <li key={index}>
                  //   <div className="cont-main img-con-div">
                  //     <div className="cont-checkbox">
                  //       <input className="display_none" type="checkbox" name={data.name}
                  //         checked={data?.isChecked || false}
                  //         onChange={handleCheckboxChange}
                  //       />

                  //       <label key={index} style={{ marginBottom: '0px' }}>
                  //         <img onClick={() => showImage(data?.url)} className="preview" style={{ border: "none", width: "38vw", height: "36vw", marginBottom: '-7px' }} src={data.url} alt={data.name} key={index} />
                  //         <span className="cover-checkbox" onClick={() => checkBoxItemChecked(data.name)}>
                  //           <svg viewBox="0 0 12 10">
                  //             <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                  //           </svg>
                  //         </span>
                  //         <span></span>
                  //       </label>
                  //     </div>
                  //   </div >
                  // </li>
                );
              })}
            </Grid>
          </div>
        )}
      </div>}
      <ImagePopUp
        showModalPopup={showModalPopup}
        imgSrc={selectedImage}
        onPopupClose={isShowPopup}
        close={close}
      ></ImagePopUp>
    </div>
  );
};

export default ImagesUpload;
