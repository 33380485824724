export const environment = {
    apiURL:'http://localhost:3000/user',
    BASE_URL: 'https://nodepeta.datainsightminer.com:4000/',
    USER: 'v1/user',
    IMAGE_SAMPLE: 'v1/photo/examples/',
    IMAGE_EX_GOOD: 'imageExGood/',
    IMAGE_EX_BAD: 'imageExBad/',
    PET_LIST: 'v1/pet/',
    PHOTO: 'v1/photo/',
    DEFAULT_SINGLE_ID: 'testsingleid',
    PRIVACY_POLICY: 'https://datainsightminer.com/Privacy_Policy_Data_Insight_v1_2024-07-10_VAI_gpo_clean_gcp.pdf',
    PRIVACY_POLICY_AGREEMENT: 'https://datainsightminer.com/Privacy_Policy_Agreement_Data_Insight_v1_2024-07-10_VAI_clean.pdf',
};
