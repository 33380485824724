import "./agreement.css";
import React, { useState, useEffect } from "react";
import { SubmitButton } from "../accountBox/common";
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../privacy/header";
import UploadService from "../../services/FileUploadService";
import { fetchGet, fetchPost, fetchPut, RegisterUser, SetToken } from "../../common/api";
import { updateJwtToken, getJwtToken, updateUserSignInStatus } from "../../common/Utils";
import { environment } from "../../Constant/urlConst";
import HeaderWithoutback from "../HeaderWithoutBack/header";
import Loader from "../Loader/Loader";
import { msg } from "../../Constant/commonMsg";
import { stringTexts } from "../../Constant/texts";
import ShowAndHidePassword from "./ShowAndHidePassword";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const AggrementScreen = () => {
    const { register, handleSubmit, errors } = useForm();
    const navigate = useNavigate();
    const [getGender, setGender] = useState(3);
    const [getAge, setAge] = useState(6);
    const [getSingleId, setSingleId] = useState('');
    const [getUsername, setUsername] = useState('');
    const [getPassword, setPassword] = useState('');
    const [getUserType, setUserType] = useState('');
    const [getConfirmPassword, setConfirmPassword] = useState('');
    const [getMaritalsts, setMaritalsts] = useState('');
    const [getChildren, setChildren] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [isPrivacyConfirmed, setisPrivacyConfirmed] = useState(false);
    const [isPrivacyAgreementConfirmed, setisPrivacyAgreementConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isOnline = navigator.onLine;
    //const [formValidate, setformValidate] = useState(false);
    const genderList = [{ 'gender': 'Gender', 'value': 3 }, { 'gender': 'Male', 'value': 1 }, {
        'gender': 'Female', 'value': 2
    }, { 'gender': 'Prefer not to disclose', 'value': 0 },];
    const ageList = [{ 'age': 'Age Group', 'value': 6 }, { 'age': '20s', 'value': 2 }, { 'age': '30s', 'value': 3 }, { 'age': '40s', 'value': 4 }, { 'age': '50s', 'value': 5 }, { 'age': 'Prefer not to disclose', 'value': 0 },];
    const materialList = [{ 'status': 'Marital Status' }, { 'status': 'Married' },
    { 'status': 'Unmarriad' }, { 'status': 'Prefer not to disclose' },];
    const childrenList = [
        { 'noc': 'Number of Children' }, { 'noc': 0 }, {
            'noc': 1
        }, { 'noc': 2 }, { 'noc': 3 }, { 'noc': 4 }, { 'noc': 5 },
    ];
    let formValidate = false;
    // let isPrivacyConfirmed = true;
    // let isPrivacyAgreementConfirmed = true;

    const privacy = localStorage.getItem("privacy");
    const privacyAgreement = localStorage.getItem("privacyagreement");

    useEffect(() => {
        //setSingleId('Kumar.gautam26')
        // console.log('privacy - ' + privacy);
        // console.log('privacyAgreement - ' + privacyAgreement);
        //getToken();
        var userRegistrationInfo = localStorage.getItem('user');
        var userInfoData = localStorage.getItem('userinfo');
        var userType = localStorage.getItem('usertype');
        var userInfo = JSON.parse(userInfoData);
        var userRegInfo = JSON.parse(userRegistrationInfo);
        setUserType(userType)
        console.log('userInfo - ' + JSON.stringify(userRegistrationInfo));
        if (userInfo?.gender)
            setGender(parseInt(userInfo.gender))
        if (userInfo?.age)
            setAge(parseInt(userInfo.age));
        if (userInfo?.username)
            setUsername(userInfo.username)
        if (userInfo?.password)
            setPassword(userInfo.password)
        if (userInfo?.singleid)
            setSingleId(userInfo.singleid)
        if (userInfo?.confirmPassword)
            setConfirmPassword(userInfo.confirmPassword)
        if (userRegInfo?.singleid) {
            console.log('singleid - ' + userRegInfo?.singleid);
            setSingleId(userRegInfo?.singleid)
        }
        if (userRegInfo?.gender)
            setGender(userRegInfo.gender)
        if (userRegInfo?.agegroup)
            setAge(userRegInfo.agegroup);
        if (userRegInfo?.singleid)
            setSingleId(userRegInfo.singleid)
        if (userRegInfo?.username)
            setUsername(userRegInfo.username)
        if (privacy == "true") {
            setisPrivacyConfirmed(true)
        }
        else if (privacyAgreement == "true") {
            setisPrivacyAgreementConfirmed(true);
        }
        if (privacyAgreement == "true" && privacy == "true") {
            console.log('both true')
            setisPrivacyConfirmed(true);
            setisPrivacyAgreementConfirmed(true);
            // localStorage.removeItem("privacy");
            // localStorage.removeItem("privacyagreement");
        }
    }, []);

    function getUsers() {
        var userInfoData = localStorage.getItem('userinfo');
        var userInfo = JSON.parse(userInfoData);
        console.log('userInfo - ' + JSON.stringify(userInfo));
        if (userInfo?.gender)
            setGender(userInfo.gender)
        if (userInfo?.age)
            setAge(userInfo.age);
        if (userInfo?.singleid)
            setSingleId(userInfo.singleid)
    }

    function btnDisagree() {
        //navigate(-1);
        navigate('/');
    }
    function btnAgree() {
        navigate('/imagesample');
    }
    function privacyPolicy() {
        //var arrdata = [{gender: getGender, age: getAge, singleid: getSingleId}];
        var arrdata = {
            "gender": getGender,
            "age": getAge,
            "singleid": getSingleId,
            "username": getUsername,
            "password": getPassword,
            "confirmPassword": getUserType == 1 ? '' : getConfirmPassword
        }
        localStorage.removeItem('userinfo');
        localStorage.setItem('userinfo', JSON.stringify(arrdata));
        console.log('arrdata-- ' + JSON.stringify(arrdata))
        console.log('singleid-- ' + getSingleId)
        console.log('getAge-- ' + getAge)
        console.log('getGender-- ' + getGender)
        navigate('/privacy-policy');
        // navigate('/pdfviewer');
    }
    function privacyPolicyAgreement() {
        var arrdata = {
            "gender": getGender,
            "age": getAge,
            "singleid": getSingleId,
            "username": getUsername,
            "password": getPassword,
            "confirmPassword": getUserType == 1 ? '' : getConfirmPassword
        }
        localStorage.removeItem('userinfo');
        localStorage.setItem('userinfo', JSON.stringify(arrdata));
        console.log('arrdata-- ' + JSON.stringify(arrdata))
        navigate('/privacy-policy-agreement');
    }
    function updateFilledDataToLocalStorage() {

    }
    const updateSingleIdValue = (event) => {
        console.log(event.target.value);
        setSingleId(event.target.value);
        //console.log('getSingleId-- '+getSingleId)
    }
    const updateNameValue = (event) => {
        setUsername(event.target.value);
    }
    const updatePasswordValue = (event) => {        
        const value = event.target.value.replace(/[^0-9]/g, '')
        if (value.length <= 4) { // Limit input to 4 digits
            setPassword(value);
          }         
    }
    const updateConfirmPasswordValue = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, '')
        console.log('value-- '+value)
        if (value.length <= 4) { // Limit input to 4 digits
            setConfirmPassword(value);
          }        
    }
    const handleOnChangeGender = (event) => {
        console.log(event.target.value)
        setGender(event.target.value);
    };
    const handleOnChangeAge = (event) => {
        console.log(event.target.value)
        setAge(event.target.value);
    };
    const handleOnChangeMaritalSts = (event) => {
        console.log(event.target.value)
        setMaritalsts(event.target.value);
    };
    const handleOnChangeChildren = (event) => {
        console.log(event.target.value)
        setChildren(event.target.value);
    };
    const onSubmit = (event) => {
        setSubmitClicked(true);
        if (validateForm()) {

            if (getIsFormValid()) {
                var reqParam = {
                    "gender": getGender,
                    "age": getAge,
                    "singleid": getSingleId,
                    "password": getPassword,
                    "confirmPassword": getUserType == 1 ? '' : getConfirmPassword
                }
                localStorage.setItem('userinfo', JSON.stringify(reqParam));
                console.log(reqParam);
                if (isOnline) {
                    if (getUserType == 1) {
                        updateUser(reqParam)
                    }
                    else {
                        createNewUser(reqParam, 'token');
                    }
                } else {
                    alert(msg.NO_INTERNET);
                }
            }
            else {
                alert(msg.PRIVACY_POLICY);
            }
        }
        else {

        }
    };



    const getToken = (user) => {
        // var reqParm = {
        //     "singleid": getSingleId
        // }
        var reqParm = {
            "singleid": 'kumar.gautam27'
        }
        fetchGet(`${environment.BASE_URL + environment.USER}`, reqParm).then(
            (response) => {
                if (response?.csrfToken) {
                    console.log('token--' + response?.csrfToken)
                    //localStorage.setItem('token', response?.csrfToken);
                    createNewUser(user, response?.csrfToken);
                }
                else {
                    alert('Semothing went wrong!! Please try later.');
                }
            }
        );
    }

    const createNewUser = (user, token) => {
        //SetToken(token); 
        console.log('token-- ' + token)
        // let ageGroupSelected = user.age;
        // if (user.age == 'Prefer not to disclose')
        //     ageGroupSelected = '0';
        // else
        //     ageGroupSelected = user.age.slice(0, -1);
        let req = {
            "username": getUsername,
            "gender": parseInt(user.gender),
            "agegroup": parseInt(user.age),
            "singleid": user.singleid,
            "password1": user.password,
            "password2": user.confirmPassword,
            "_csrf": token
        }
        setIsLoading(true);
        fetchPost(`${environment.BASE_URL + environment.USER}`, req, getJwtToken()
        ).then(
            (response) => {
                if (response) {
                    setIsLoading(false);
                    console.log(`response${JSON.stringify(response)}`)
                    if (response.message == "Register successful") {
                        if (response?.jwt)
                            //updateJwtToken(response?.jwt);
                            //localStorage.setItem('user', JSON.stringify(response.user));
                            //localStorage.removeItem('userinfo');
                            var reqParam = {
                                "gender": getGender,
                                "age": getAge,
                                "singleid": getSingleId,
                                "username": getUsername,
                                "password": '',
                                "confirmPassword": ''
                            }
                        localStorage.setItem('userinfo', JSON.stringify(reqParam));
                        localStorage.setItem('usertype', 1);
                        setPassword('');
                        setConfirmPassword('');
                        setUserType(1);
                        alert(msg.USER_REGISTERED);
                    }
                    else {
                        alert(msg.SOMETHING_WENT_WRONG);
                    }
                }
                else {
                    setIsLoading(false);
                    alert(msg.SOMETHING_WENT_WRONG);
                }
            },
            (err) => {
                setIsLoading(false);
                console.log(`error ${err}`);
                navigate('/signout');
            }
        );
    }

    const updateUser = (user) => {
        let req = {
            "username": getUsername,
            "gender": parseInt(user.gender),
            "agegroup": parseInt(user.age),
            "singleid": user.singleid,
            "password": user.password,
        }
        setIsLoading(true);
        fetchPut(`${environment.BASE_URL + environment.USER}`, req, getJwtToken()
        ).then(
            (response) => {
                if (response) {
                    setIsLoading(false);
                    console.log(`response${JSON.stringify(response)}`)
                    if (response.message == "password is correct.") {
                        if (response?.jwt)
                            updateJwtToken(response?.jwt);
                        //localStorage.setItem('user', JSON.stringify(response));
                        localStorage.setItem('user', JSON.stringify(response.user));
                        //localStorage.removeItem('userinfo');
                        var reqParam = {
                            "gender": getGender,
                            "age": getAge,
                            "singleid": getSingleId,
                            "username": getUsername,
                            "password": '',
                            "confirmPassword": ''
                        }
                        localStorage.setItem('userinfo', JSON.stringify(reqParam));
                        localStorage.setItem('usertype', 1);
                        updateUserSignInStatus();
                        navigate('/imagesample');
                    }
                    else {
                        setPassword('');
                        alert(response?.message);
                    }
                }
                else {
                    setIsLoading(false);
                    alert(msg.SOMETHING_WENT_WRONG);
                }
            },
            (err) => {
                setIsLoading(false);
                console.log(`error ${err}`);
                navigate('/signout');
            }
        );
    }

    const notify = () => {
        //toast("This feature is in progress!!"); 
        toast.info('This feature is in progress!!', {
            position: "top-center",
            className: 'agreement-optiion',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };
    const getIsFormValid = () => {
        return (
            isPrivacyAgreementConfirmed &&
            isPrivacyConfirmed
        );
    };
    const confirmPasswordOnKeyDown = (event) => {
        
        if (event.target.value.length == 4) { return false; }
        
    }
    function validateForm() {
        if (getGender == 3 || getGender == '') {
            formValidate = false;
        }
        // else if (getMaritalsts == 'Marital Status' || getGender == '') {
        //     formValidate = false;
        // }
        else if (getAge == 6 || getAge == '') {
            formValidate = false;
        }
        // else if (getChildren == 'Number of Children' || getChildren == '') {
        //     formValidate = false;
        // }
        else if (getUsername.trim() == '') {
            formValidate = false;
        }
        else if (getSingleId == '') {
            formValidate = false;
        }
        else if (getPassword == '') {
            formValidate = false;
        }
        else if (getUserType == 0 && getConfirmPassword == '') {
            formValidate = false;
        }
        else if (getUserType == 0 && getConfirmPassword != getPassword) {
            formValidate = false;
        }
        else {
            formValidate = true;
        }
        return formValidate;
    }

    return (
        <div className="parent-div-agreement app-container">
            <HeaderWithoutback headerText={'AI Data Collection'} />
            {isLoading ? <Loader /> : <div className="agreement-parent-container-div">
                <div className="content-div-agreement">
                    {/* <h1 className="header-agreement">AI Data Collection</h1> */}
                    <p className="content-agreement agreement-font">{stringTexts.AGREEMENT_TEXT1}</p>
                    <p className="content-agreement agreement-font">{stringTexts.AGREEMENT_TEXT2}</p>
                    <p className="content-agreement agreement-font">{stringTexts.AGREEMENT_TEXT3}</p>
                    <p className="content-agreement agreement-font">{stringTexts.AGREEMENT_TEXT4}</p>
                </div>
                <div className="policy-agreement">
                    <a className="anchor-agreement" onClick={privacyPolicyAgreement}>
                        Privacy Policy Agreement
                        {
                            isPrivacyAgreementConfirmed ?
                                <span className="checkbox-agreement-privacy">  &#10003;</span> :
                                <span className="checkbox-agreement-privacy"></span>
                        }
                    </a>
                    <br />

                    <a className="anchor-agreement" onClick={privacyPolicy}>
                        Privacy Policy
                        {
                            isPrivacyConfirmed ?
                                <span className="checkbox-agreement-privacy">  &#10003;</span> :
                                <span className="checkbox-agreement-privacy"></span>
                        }
                    </a>
                    {/* <h3>&#10003;</h3>
                <span>&#9989;</span> */}
                </div>
                <div className="dropdown-div">
                    <p className="content-agreement agreement-font">{stringTexts.AGREEMENT_TEXT5}</p>
                </div>
                {/* <form onSubmit={onSubmit}> */}
                <div>
                    <div className="select-div">
                        <input type="text"  className="Input single-id-input-agreement" style={{ border: '1px solid rgb(35, 193, 227)' }} disabled={true} value={getSingleId} placeholder="Single ID" onChange={updateSingleIdValue} />
                        {submitClicked && (getSingleId == '') && <p style={{ color: 'red', fontFamily: 'SamsungFont', paddingLeft: '10px' }} className="error-msg">Single ID is required.</p>}
                    </div>
                    <div className="select-div content-div-agreement">
                        {/* <label type="text" className="Input user-name-label-agreement">User Name</label> */}
                        <input type="text" className="Input single-id-input-agreement" value={getUsername} style={{ border: getUserType == '1' ? '1px solid rgb(35, 193, 227)' : '' }} disabled={getUserType == '1'} placeholder="User name" onChange={updateNameValue} />
                        {submitClicked && (getUsername == '') && <p style={{ color: 'red', fontFamily: 'SamsungFont', paddingLeft: '10px' }} className="error-msg">Username is required.</p>}
                    </div>

                    <div className="select-div">
                        <select name="gender_select" className="Input" style={{color: getGender == 3 ? 'lightgray' : 'gray'}} value={getGender} onChange={handleOnChangeGender}>
                            {genderList.map((option, i) => (
                                <option className="select-option-box" key={i} value={option.value}>{option.gender}</option>
                            ))}
                        </select>
                        {submitClicked && (getGender == 3 || getGender == '') && <p style={{ color: 'red', fontFamily: 'SamsungFont', paddingLeft: '10px' }} className="error-msg">Gender is required.</p>}
                    </div>
                    <div className="select-div">
                        <select name="age_select" className="Input" value={getAge} style={{color: getAge == 6 ? 'lightgray' : 'gray'}} onChange={handleOnChangeAge}>
                            {ageList.map((option, i) => (
                                <option className="agreement-optiion select-option-box" key={i} value={option.value}>{option.age}</option>
                            ))}
                        </select>
                        {submitClicked && (getAge == 6 || getAge == '') && <p style={{ color: 'red', fontFamily: 'SamsungFont', paddingLeft: '10px' }} className="error-msg">Age is required.</p>}
                    </div>
                    <div className="select-div password-div">
                        <input type={showPassword ? 'number' : 'password'} maxLength="4" className="Input single-id-input-agreement" value={getPassword} placeholder="Password" onChange={updatePasswordValue} />
                        <button type="button" className="password-eye-button" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                        {submitClicked && (getPassword == '') && <p style={{ color: 'red', fontFamily: 'SamsungFont', paddingLeft: '10px' }} className="error-msg">Password is required.</p>}
                    </div>
                    {getUserType == '0' ? <div className="select-div">
                        <input type="password" className="Input single-id-input-agreement" value={getConfirmPassword} placeholder="Confirm Password" onChange={updateConfirmPasswordValue} />
                        {submitClicked && (getConfirmPassword == '') && <p style={{ color: 'red', fontFamily: 'SamsungFont', paddingLeft: '10px' }} className="error-msg">Confirm Password is required.</p>}
                        {submitClicked && (getUserType != 1) && (getConfirmPassword != '') && (getPassword != '') && (getConfirmPassword != getPassword) && <p style={{ color: 'red', fontFamily: 'SamsungFont', paddingLeft: '10px' }} className="error-msg">Password & Confirm Password do not match.</p>}
                    </div> : ''}

                </div>
                <ToastContainer bodyClassName="toastBody" />
                <div>
                    <div className="row-agreement row-agreement-format">
                        <div className="column-agreement right-agreement">
                            <SubmitButton type="submit" style={{ maxWidth: '100%' }} onClick={onSubmit} disabled={!getIsFormValid()}>{getUserType == 1 ? 'Sign In & Info Update' : 'Privacy Policy Agree & Sign On'}</SubmitButton>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
};
export default AggrementScreen;
