import "./pet-list-all.css"
import { useNavigate } from 'react-router-dom';
import Header from '../privacy/header';
import Switch from "react-switch";
import React, { useState, useEffect, useRef } from "react";
import PetListPopUp from "../utility/PetListPopUp";
import { fetchGet, fetchPost, fetchDelete } from "../../common/api";
import { environment } from "../../Constant/urlConst";
import { updateJwtToken, getJwtToken } from "../../common/Utils";
import { msg } from "../../Constant/commonMsg";
import Loader from "../Loader/Loader";
import { SubmitButton } from "../accountBox/common";
const PetListAll = () => {
    const [chkStatus, setChkStatus] = useState(false);
    const nameData = useRef({});
    const breedData = useRef({});
    const noteData = useRef({});
    const [petData, setPetData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModalPopup, setShowModalPopup] = useState(false);
    const navigate = useNavigate();
    const isOnline = navigator.onLine;

    useEffect(() => {
        if (isOnline) {
            getPetList();
        } else {
            alert(msg.NO_INTERNET);
        }
    }, [localStorage.getItem('userinfo')]);

    const getPetList = () => {
        let letUserData = JSON.parse(localStorage.getItem('user'));
        let userId = letUserData._id;
        console.log('getJwtToken()-- ' + getJwtToken())
        fetchGet(`${environment.BASE_URL + environment.PET_LIST + userId}`, getJwtToken()).then(
            (response) => {
                setIsLoading(false)
                if (response) {
                    console.log(`response${response}`)
                    setPetData(response.pets);
                }
                else {
                    alert(msg.SOMETHING_WENT_WRONG);
                }
            },
            (err) => {
                setIsLoading(false);
                console.log(`error ${err}`);
                navigate('/signout');
            }
        );
    }
    function handleChange(checked) {
        setChkStatus(checked);
        console.log('checked-- ' + checked);
    };
    const nevigateToPetPhotoPage = (item, index) => {
        navigate(`/petdetails/${JSON.stringify(item)}`)
    };
    const isShowPopup = (status) => {
        setShowModalPopup(status);
    };
    const clear = () => {
        console.log('petData-- ' + JSON.stringify(petData))
    }

    const save = (data) => {
        //setPetData([...petData, data]);
        //console.log('petData-- ' + JSON.stringify(petData))
        if (isOnline) {
            if(data?.name.trim()=='' || data?.note.trim()=='' || data?.breed.trim()=='' )
            alert(msg.ALL_FIELDS_MANDATORY);            
            else
            AddPetData(data);
        } else {
            alert(msg.NO_INTERNET);
        }
    }
    const AddPetData = (petData) => {
        let letUserData = JSON.parse(localStorage.getItem('user'));
        let userId = letUserData._id;
        let req = {
            "name": petData.name,
            "type": petData.note,
            "breed": petData.breed,
            "hasgrowthphoto": 'true',
        }
        setIsLoading(true)
        fetchPost(`${environment.BASE_URL + environment.PET_LIST + userId}`, req, getJwtToken()).then(
            (response) => {
                setIsLoading(false)
                if (response) {
                    console.log(`response${response}`)
                    if (response.message == "Success") {
                        //localStorage.setItem('user', JSON.stringify(response));
                        //localStorage.removeItem('userinfo');
                        //navigate('/imagesample');
                        getPetList();
                    }
                    else {
                        alert('Semothing went wrong!! Please try later.');
                    }
                }
                else {
                    alert('Semothing went wrong!! Please try later.');
                }
            },
            (err) => {
                setIsLoading(false)
                console.log(`error ${err}`);
                navigate('/signout');
            }
        );
    }

    const deletePet = (item, targetIndex) => {
        console.log(JSON.stringify(item) + ' -index- ' + targetIndex);
        //const newArray = petData.filter((item, index) => index !== targetIndex);
        //setPetData(newArray);
        
        if (isOnline) {
            if (window.confirm("Delete the item?")) {
                deletePetItem(item._id);
            }            
        } else {
            alert(msg.NO_INTERNET);
        }

    };
    const deletePetItem = (petId) => {
        setIsLoading(true)
        fetchDelete(`${environment.BASE_URL + environment.PET_LIST + petId}`, getJwtToken()).then(
            (response) => {
                setIsLoading(false)
                if (response) {
                    console.log(`response${response}`)
                    getPetList();
                }
                else {
                    alert('Semothing went wrong!! Please try later.');
                }
            },
            (err) => {
                setIsLoading(false)
                console.log(`error ${err}`);
                navigate('/signout');
            }
        );
    }
    function AddPet() {
        setShowModalPopup(true);
    }
    return (
        <div className="parent-div app-container">
            <Header headerText={'Pet List All'} page={-1} showback={true}/>
            { isLoading ? <Loader/> : <div style={{height:'100%'}}>
            <div className="MuiBox-root css-fxid9l" onClick={AddPet}><span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeSmall css-c6zp6d" aria-hidden="true">+</span></div>
            {petData?.length > 0 ?
                <div className="MuiBox-root css-12brd4o list-background-color">
                    <ul className="MuiBox-root css-1x8070m">
                        {petData.map((item, index) => (
                            <li className="MuiBox-root css-a2kwn6" style={{paddingTop: '10px'}} key={index}>
                                <div className="MuiBox-root css-pm8idd">
                                    <div className="MuiBox-root css-11cypzi">
                                        <span className="MuiTypography-root MuiTypography-button css-qe485f">{`(${index + 1}) `}Pet Info</span>
                                        <div className="MuiBox-root css-ql19x3">

                                        </div>
                                    </div>
                                    <div className="MuiBox-root css-1264yew">
                                        <span className="MuiTypography-root MuiTypography-caption css-19q0ucf">Pet Name:&nbsp;&nbsp;&nbsp;
                                            <span className="MuiTypography-root MuiTypography-caption css-1ac3a30">{item.name}</span>
                                        </span>
                                    </div><div className="MuiBox-root css-1264yew">
                                        <span className="MuiTypography-root MuiTypography-caption css-19q0ucf">Breed:&nbsp;&nbsp;&nbsp;
                                            <span className="MuiTypography-root MuiTypography-caption css-462t1e">{item.breed}</span>
                                        </span>
                                    </div><span className="MuiTypography-root MuiTypography-caption css-19q0ucf">Pet Type:&nbsp;&nbsp;&nbsp;
                                        <span className="MuiTypography-root MuiTypography-caption css-462t1e">{item.type}</span>
                                    </span>

                                    <div className="switch-div">
                                        <label className="label-switch-parent">
                                            <span className="MuiTypography-root MuiTypography-caption css-19q0ucf">Include human face</span>
                                            <Switch height={18} width={35} className="label-switch-child1" onChange={handleChange} checked={item?.includehuman ? item?.includehuman : false} />
                                        </label>
                                    </div>
                                    <div>
                                        <label className="label-switch-parent">
                                            <span className="MuiTypography-root MuiTypography-caption css-19q0ucf">Include multiple pet</span>
                                            <Switch height={18} width={35} className="label-switch-child2" onChange={handleChange} checked={item?.multiplepet ? item?.multiplepet : false} />
                                        </label>
                                    </div>
                                    <div className="MuiBox-root css-ql19x3" style={{marginTop:'8px'}}>
                                        <div className="MuiBox-root css-nk2l5f" style={{paddingRight: '10px'}}>
                                            {/* <button onClick={() => deletePet(item, index)} className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-h3pk10" tabIndex="0" type="button">
                                                delete
                                            </button> */}
                                            <SubmitButton type="submit" style={{padding: '5px'}} onClick={() => deletePet(item, index)}>Delete</SubmitButton>
                                        </div>
                                        <div className="MuiBox-root css-nk2l5f" style={{paddingLeft: '10px'}}>
                                            {/* <button onClick={() => nevigateToPetPhotoPage(item, index)} className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1u89yhd" tabIndex="0" type="button">
                                                Pet Photo
                                            </button> */}
                                            <SubmitButton type="submit" style={{padding: '5px'}} onClick={() => nevigateToPetPhotoPage(item, index)}>Pet Photo</SubmitButton>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}

                    </ul>
                </div>
                : <div className="parent-no-pet-msg"><div className="child-no-pet-msg"> Pet not available, Add a pet to upload images. </div></div>
            }
            <PetListPopUp
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                nameData={nameData}
                breedData={breedData}
                noteData={noteData}
                clear={clear}
                save={save}
            ></PetListPopUp>
            </div>}            
        </div>
    );
};
export default PetListAll;