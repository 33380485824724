import React, { useState, useEffect, useRef } from "react";
import { updateUserSignOutStatus } from "../../common/Utils";
import "./signout.css"
const SignOut = () => {
    updateUserSignOutStatus();
    return (
        <div className="main-div-warning">
            <h1>Warning!</h1>
            <p>Please read this message carefully:</p>
            <div className="warning">
                <strong>Your token was expired or has been tampered with.</strong><br />
                <div className="maintext">
                    You need to close your current browser and access it again through the Chatbot link.
                    <br /> In case of repeated problems, please contact the administrator.
                </div>
            </div>
        </div>
    );
};

export default SignOut;
