import "./image-sample.css"
import { SubmitButton } from "../accountBox/common";
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import HeaderWithoutback from "../HeaderWithoutBack/header";
import Collapsible from 'react-collapsible';
import { fetchGet, fetchPost } from "../../common/api";
import { environment } from "../../Constant/urlConst";
import { stringTexts } from "../../Constant/texts";
import { updateJwtToken, getJwtToken } from "../../common/Utils";
import Loader from "../Loader/Loader";
import { msg } from "../../Constant/commonMsg";
import ImagePopUp from "../ImagePopUp/ImagePopUpModel";
import Header from "../privacy/header";
const ImageSample = () => {
    const [goodImgList, setGoodImg] = useState([]);
    const [badImgList, setBadImg] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const navigate = useNavigate();
    const isOnline = navigator.onLine;
    function toUploadPage() {
        navigate('/petlistall');
    }
    useEffect(() => {
        let letUserData = JSON.parse(localStorage.getItem('user'));
        let userId = letUserData._id;
        getSampleImage(userId);
    }, []);

    const isShowPopup = (status) => {
        setShowModalPopup(status);
    };

    function showImage(img){
        console.log('img-- '+img);
        setSelectedImage(img);
        setShowModalPopup(true);
    }
    function close(){
        console.log('image popup close');
    }

    const getSampleImage = (userId) => {        
        if (isOnline) {
            fetchGet(`${environment.BASE_URL + environment.IMAGE_SAMPLE + userId}`, getJwtToken()).then(
                (response) => {
                    if (response) {
                        setIsLoading(false);                        
                        let goodImgArray = [];
                        let badImgArray = [];
                        response?.imgListGood?.forEach(function (goodImg) {
                            goodImgArray.push(environment.BASE_URL + environment.IMAGE_EX_GOOD + goodImg)
                        });
                        setGoodImg(goodImgArray);
                        response?.imgListBad?.forEach(function (badImg) {
                            badImgArray.push(environment.BASE_URL + environment.IMAGE_EX_BAD + badImg)
                        });
                        setBadImg(badImgArray);
                    }
                    else {
                        setIsLoading(false);
                        alert(msg.SOMETHING_WENT_WRONG);
                    }
                }
                ,
                (err) => {
                    setIsLoading(false);
                    console.log(`error ${err}`);
                    navigate('/signout');
                }
            );
        }
        else {
            alert(msg.NO_INTERNET);
        }
    }

    return (
        <div className="parent-div-image-sample app-container">
            {/* <HeaderWithoutback headerText={'Example of Collected Photo'} /> */}
            <Header headerText={'Example of Collected Photo'} page={'/agreement'} showback={true}/>
            {isLoading ? <Loader /> : <div className="image-sample-parent-container-div">
                <div className="content-div-image-sample">
                    {/* <h1 className="header-image-sample">Example of Collected Photo</h1> */}
                    <p className="content-image-sample image-sample-font">{stringTexts.IMAGE_EXAMPLE_TEXT1}</p>
                    <p className="content-image-sample image-sample-font">{stringTexts.IMAGE_EXAMPLE_TEXT2}</p>
                    <p className="content-image-sample image-sample-font">{stringTexts.IMAGE_EXAMPLE_TEXT3}</p>
                </div>
                <div className="div-button-navigate-upload">
                    <SubmitButton type="submit" onClick={toUploadPage} style={{fontSize: 'large'}}>Go to pet list page</SubmitButton>
                </div>
                <div className="image-container-div-image-sample">
                    <Collapsible trigger='Good Examples' open='true'>
                        <ul className="list_ul">
                            {goodImgList.map((data, index) => {
                                return (
                                    <li key={index} onClick={() =>showImage(data)}><img className="preview" style={{ width: "40vw", height: "40vw", marginLeft:'-1px' }} src={data} alt={data} key={index} /></li>
                                );
                            })}
                        </ul>
                    </Collapsible>
                    <Collapsible trigger='Bad Examples' open='true'>
                        <ul className="list_ul">
                            {badImgList.map((data, index) => {
                                return (
                                    <li key={index} onClick={() =>showImage(data)}><img className="preview" style={{ width: "40vw", height: "40vw", marginLeft:'-1px' }} src={data} alt={data} key={index} /></li>
                                );
                            })}
                        </ul>
                    </Collapsible>
                </div>
            </div>}
            <ImagePopUp
                showModalPopup={showModalPopup}
                imgSrc={selectedImage}                
                onPopupClose={isShowPopup}                
                close={close}                
            ></ImagePopUp>
        </div>
    );
};

export default ImageSample;