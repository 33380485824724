import "./privacy-policy.css"
import { useNavigate } from 'react-router-dom';
import { Document, Page, pdfjs } from "react-pdf";
import { environment } from "../../Constant/urlConst";
import Header from "./header";
import { useState } from 'react';
import Footer from "./footer";
import { SubmitButton } from "../accountBox/common";
import React from 'react';
import { isMobile } from 'react-device-detect';

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    //localStorage.setItem("privacy", "false");

    function getdocUrl() {
        if (isMobile) {
            return 'https://docs.google.com/gview?embedded=true&url=https://arxiv.org/pdf/quant-ph/0410100.pdf';
        }
        else {
            return 'https://arxiv.org/pdf/quant-ph/0410100.pdf';
        }
    }
    function redirectToImageInfoPage() {
        localStorage.setItem("privacy", "true");
        navigate('/agreement');
    }
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <div className="parent-div">

            <Header headerText={'Privacy Policy'} page={-1} showback={true} />
            <div className="pdf-div holds-the-iframe">
                {/* <iframe src={getdocUrl() + '#toolbar=0&navpanes=0'} exact="true" width="100%" height="95%" /> */}
                <iframe className="pdf"
                    src={environment.PRIVACY_POLICY + '#toolbar=0&navpanes=0'}                    
                    exact="true" width="100%" height="100%">
                </iframe>

            </div>
            <div className="btn-confirm-div d-flex">
                <SubmitButton
                    variant="outline-primary" size="lg"
                    onClick={redirectToImageInfoPage}
                    className="btn btn-success btn-sm mt-auto btnSubmit"
                >
                    Confirm
                </SubmitButton>
            </div>

        </div>
    );
};
export default PrivacyPolicy;
