import "./privacy-policy-agreement.css"
import { useNavigate } from 'react-router-dom';
import { pdfjs } from "react-pdf";
import Header from '../privacy/header';
import { SubmitButton } from "../accountBox/common";
import React, { useEffect } from "react";
import { isMobile } from 'react-device-detect';
import { environment } from "../../Constant/urlConst";
const PrivacyPolicyAgreement = () => {
    const navigate = useNavigate();
    //localStorage.setItem("privacyagreement","false");
    function redirectToImageInfoPage() { 
        localStorage.setItem("privacyagreement","true");               
        navigate('/agreement');
    }

    return (
        <div className="parent-div">            
            <Header headerText={'Privacy Policy Agreement'} page={-1} showback={true}/>
            <div className="pdf-div holds-the-iframe">
            <iframe className="pdf"
                    src={environment.PRIVACY_POLICY_AGREEMENT + '#toolbar=0&navpanes=0'}                    
                    exact="true" width="100%" height="100%">
                </iframe>
            </div>
            <div className="btn-confirm-div d-flex">
                <SubmitButton
                    variant="outline-primary" size="lg"
                    onClick={redirectToImageInfoPage}
                    className="btn btn-success btn-sm mt-auto btnSubmit-agreement"
                >
                    Confirm
                </SubmitButton>
            </div>            
        </div>
    );
};
export default PrivacyPolicyAgreement;
